.navbar-brand{
  color: white;
  font-size: calc(8px + 1.5vmin);

  transition: all .15s ease-in-out;
  //transition: color .15s ease-in-out,
  //            background-color .15s ease-in-out,
  //            border-color .15s ease-in-out,
  //            font-size .15s ease-in-out;
}

.nav-link{
  color: white;
  font-size: calc(5px + 1vmin);

  transition: all .15s ease-in-out;
}