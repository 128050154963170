@import "src/GlobalValues";
@import "src/BootstrapOverride";

@font-face { font-family: "Slkscrb"; src: url(../public/Fonts/slkscrb.ttf); }

* {
  font-family: Slkscrb, sans-serif;
}

body {
  background-color: #45283c;
  color: white;

}

// https://codepen.io/zachkrall/pen/MWWGMPx
.wavy {
  animation-name: wavy;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  position: relative;
  top: 0;
  left: 0;
}
@keyframes wavy {
  0% {
    top: 2px;
  }
  50% {
    top: -2px;
  }
  100% {
    top: 2px;
  }
}

.mainNavbarElement:hover {
  color: #45283c;
  //font-size: calc(1.1 * var(--bs-navbar-brand-font-size));
  -webkit-transform: scale(1.1);
}

.mainNavbarElement:focus {
  //color: #5d3563;
  color: #fff;
  //font-size: calc(1.1 * var(--bs-navbar-brand-font-size));
  -webkit-transform: scale(1);
}

.App {
  text-align: center;
}

.App-logo {
  height: 10vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 1.25s cubic-bezier(0.75, 0, 0.25, 1);
  }
}

.App-body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

@media (max-width: 768px) {
  .App-body{
    justify-content: flex-start;
  }
}


a{
  color: #d77bba;
}

.App-link {
  color: #d77bba;
}

.news-panel{
  background-color: #76428a;
  border-radius: 12px;
  box-shadow: 0 4px 6px 1px #00000080;
  transition-duration: 0.25s;
}

.news-panel:hover{
  background-color: #5e356e;
  transform: scale(1.1);
  transition-duration: 0.25s;
}

.news-post-display {
  font-size: calc(10px + 1.5vmin);
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.mailchimpForm{
  background-color: #222034;
  padding: 5vh 27.5vw;
  font-size: 1vw;
}

@media (max-width: $phoneMaxWidth) {
  .mailchimpForm {
    padding: 5vh 5vw;
    font-size: 3vw;
  }
}